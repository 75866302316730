import React from 'react'
import classNames from 'classnames';
import logo from '../images/LogoHorizontal.svg';

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenuVisible: false
        };
    }
    toggleBurger= () => {
        this.setState({mobileMenuVisible: !this.state.mobileMenuVisible});
    }
    render() {

        return (
            <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" href="https://bulma.io">
                        <img src={logo} alt="logo"/>
                    </a>
                    {/* eslint-disable */}
                    <a
                        role="button"
                        class="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasicExample"
                        onClick={this.toggleBurger}
                        href="#"
                        >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                    {/* eslint-disable */}
                </div>
                <div class={classNames('animated navbar-menu',{
                    'is-active': this.state.mobileMenuVisible,
                    'bounceInRight':this.state.mobileMenuVisible,
                    })}>
                    <a class="navbar-item" href="/">
                        Home
                    </a>
                    <a class="navbar-item" href="http://www.medextetica.com/laser">
                        Laser
                    </a>
                    <a class="navbar-item" href="http://www.medextetica.com/xshape">
                        xShape
                    </a>
                    <a class="navbar-item" href="http://www.medextetica.com/hifu">
                        Hifu
                    </a>
                </div>

            </nav>
        )
    }
}

export default NavBar;